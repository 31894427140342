<template>
  <div className="container">
    <div className="sider">
      <button className="btn" @click="scrollTo('efficiency')">全部</button>
      <button className="btn" @click="scrollTo('efficiency')">效率</button>
      <button className="btn" @click="scrollTo('marketing')">营销</button>
      <button className="btn" @click="scrollTo('entertainment')">娱乐</button>
    </div>
    <div className="right-content">

      <div class="right-content-sub" v-if="isShowQm" style="display: inline-block;margin:10px 10px 0 10px;">
        <div><a href="http://www.wph6.cn/" target="_blank" style="display:block;margin:0 auto;width:100%;"><img :src="require(`@/views/images/AIqm1.png`)" style="max-width: 100%;max-height: 100%;"></a></div>
      </div>

      <div v-for="(group, i) in groups" :key="i" :ref="group.ref" class="right-content-sub">
        <div v-for="(item, j) in items.filter(it => it.ref === group.ref)" :key="j" class="box"
             @click="handleBoxClick($event, group.ref, j, item.title, item.description)"
             v-bind:style="{ cursor: boxCursor }">
          <div class="box-content">
            <img :src="require(`@/views/images/${item.pic}`)">
            <p class="title">{{ item.title }}</p>
            <p class="description">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" active-color="#16a37f" inactive-color="#000">
      <van-tabbar-item icon="chat-o" to="/chart">问答</van-tabbar-item>
      <van-tabbar-item icon="like-o" dot to="/examples">应用</van-tabbar-item>
      <!--<van-tabbar-item icon="question-o" to="/help">帮助</van-tabbar-item>-->
      <van-tabbar-item icon="question-o" @click="toMission">任务</van-tabbar-item>
      <van-tabbar-item icon="link-o" url="https://www.wph1.cn/">高级版</van-tabbar-item>
      <van-tabbar-item icon="user-circle-o" @click="toHome">我的</van-tabbar-item>
    </van-tabbar>
    <van-popup v-model:show="mobilePopupShow" :style="{ padding: '30px' }">
      <div class="content">
        <div class="main">
          <div class="mainContainer">
            <div class="loginName">账号登录</div>
            <div class="main_form">
              <van-form @submit="onSubmit">
                <van-cell-group inset>
                  <van-field style="border: 1px solid #D8D8D8;margin-top:2vh"
                             v-model="form.username"
                             name="请输入手机号"
                             placeholder="请输入手机号"
                             :rules="[{ required: true, message: '请输入手机号' },
                { validator: validatorTel, message: '手机号输入不合法' }]"
                  />
                  <van-field style="border: 1px solid #D8D8D8;margin:2vh 0"
                             v-model="form.password"
                             :type="isShow ? 'text' : 'password' "
                             name="请输入密码"
                             placeholder="请输入密码"
                             :right-icon="isShow ? 'eye-o' : 'closed-eye'"
                             @click-right-icon="changePasswordIcon"
                             :rules="[{ required: true, message: '请输入密码' }]"
                  />
                </van-cell-group>
                <div>
                  <van-button block type="primary" native-type="submit" class="formButton" color="#16a37f" style="margin-bottom:2vh;">
                    登录
                  </van-button>
                </div>
              </van-form>
              <div class="row-between toPasswordRegister">
                <div class="pointerCursor" @click="$router.push('/forgetPassword')">
                  忘记密码？
                </div>
                <div class="pointerCursor" @click="$router.push('/register')">
                  新用户账号注册
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top:20px;text-align:center;font-size: .14rem;">注册或登录即表示您已阅读并同意<a style="color:#3bcb73;" @click="$router.push('/termsService')">用户协议</a></div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="pcPopupShow" :style="{ padding: '64px' }" closeable>
      <div style="text-align:center;font-size: .14rem;">微信扫码登录助手AI</div>
      <div><img :src="qrCode" alt="登录二维码" @click.stop></div>
      <div style="text-align:center;font-size: .14rem;">使用微信扫码-关注公众号登录</div>
      <div style="text-align:center;font-size: .14rem;">注册或登录即表示您已阅读并同意<a style="color:#3bcb73;" @click="$router.push('/termsService')">用户协议</a></div>
    </van-popup>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { checkWechatScan, checkWechatUserAuth, getQrcode } from '@/api/login'
import { reactive, toRefs } from 'vue'
import Cookies from 'js-cookie'
import { _isMicroMessager } from '../utils/common'

export default {
  name: "examples",
  methods: {
    scrollTo(refName) {
      const element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth" });
    },
    handleBoxClick(event, ref, index, title, description) {
      this.$router.push({
        name: 'application',
        params: {
          ref: ref,
          index: index,
          title: title,
          description: description
        }
      });
    }
  },
  data() {
    return {
      boxCursor: 'pointer',
      isShowQm: !(_isMicroMessager()),
      items: [
        {ref: 'efficiency', index: 0, title: '日报', description: '根据您今日的工作内容，帮您生成今日日报', pic: 'icon_efficiency_rb.png'},
        {ref: 'efficiency', index: 1, title: '周报', description: '根据您本周的工作内容，帮您生成本周周报', pic: 'icon_efficiency_zb.png'},
        {ref: 'efficiency', index: 2, title: '短视频脚本', description: '抖音|快手|小红书，短视频创作', pic: 'icon_efficiency_zb.png'},
        {ref: 'efficiency', index: 3, title: '旅游计划', description: '根据你的旅游要求，帮您生成旅游计划', pic: 'icon_efficiency_ly.png'},
        {ref: 'marketing', index: 0, title: '小红书', description: '帮助你生成小红书的Emoji风格文案', pic: 'icon_marketing_xhs.png'},
        {ref: 'marketing', index: 1, title: '大众点评|美团', description: '探店打卡，帮您生成大众点评|美团的风格文案', pic: 'icon_marketing_hotel.png'},
        {ref: 'marketing', index: 2, title: '淘宝|京东', description: '商品评价，帮您生成购物风格文案', pic: 'icon_marketing_goods.png'},
        {ref: 'marketing', index: 3, title: '朋友圈营销文案', description: '一键转换，生成带格式适合朋友圈传播文案', pic: 'icon_marketing_pyqyingxiao.png'},
        {ref: 'entertainment', index: 0, title: '哄女友帮助', description: '女朋友生气怎么办？用我帮您分析', pic: 'icon_entertainment_girl_friend1.png'},
        {ref: 'entertainment', index: 1, title: '单身狗分析', description: '分析如何帮您追女朋友|男朋友', pic: 'icon_entertainment_girl_friend2.png'},
        {ref: 'entertainment', index: 2, title: '节日祝福', description: '过节了，帮你写祝福语', pic: 'icon_entertainment_blessing.png'},
        {ref: 'entertainment', index: 3, title: 'AI解梦', description: '根据您的梦境，分析您的当前运势', pic: 'icon_entertainment_dream.png'},
        {ref: 'entertainment', index: 4, title: '高情商回复', description: '帮助你高情商答复而不伤和气', pic: 'icon_entertainment_gqs.png'},
        {ref: 'entertainment', index: 5, title: '甩锅助手', description: '帮助你分析如何推卸责任', pic: 'icon_entertainment_blameSifting.png'},
        {ref: 'entertainment', index: 6, title: '夸夸助手', description: '教你如何夸人', pic: 'icon_entertainment_brag.png'},
        {ref: 'entertainment', index: 7, title: '吵架助手', description: '教你如何不再脏字吵架', pic: 'icon_entertainment_cj.png'}
      ],
      groups: [
        {ref: 'efficiency', title: '效率工具'},
        {ref: 'marketing', title: '营销工具'},
        {ref: 'entertainment', title: '娱乐工具'}
      ]
    }
  },
  setup() {

    let active = ref(1);
    const route = useRoute();
    const router = useRouter();

    const store = useStore()
    const model = computed(() => store.getters.isModel)

    const mobilePopupShow = ref(false);
    const pcPopupShow = ref(false);
    let isLogin = store.getters.isLogin == 0 ? false : true;
    const state2 = reactive({
      qrCode: '',
      scanStatus: 0,
      to: ''
    })

    const toMission = () => {
      state2.to = 'mission'
      showPopup()
    }

    const toHome = () => {
      state2.to = 'home'
      showPopup()
    }
    const checkQrResult = () => {
      checkWechatScan()
          .then(res => {
            state2.scanStatus = res.data.scanStatus
            if(state2.scanStatus == 1){
              pcPopupShow.value = false
              const adminToken = res.data.adminToken
              if(adminToken != undefined){
                Cookies.set("Admin-Token", adminToken, { expires: 7 })
              }
              isLogin = true
              if (state2.to == 'mission'){
                active.value = 2;
                router.push('/mission')
              } else {
                active.value = 0;
                location.reload()
              }
            }else if(state2.scanStatus == 0 && pcPopupShow.value == true){
              setTimeout(() => {
                checkQrResult()
              }, 1000)
            }
          })
          .catch((msg) => {})
    }
    const showPopup = () => {
      if(isLogin){
        if(state2.to == 'mission'){
          router.push('/mission')
        }else {
          router.push('/home')
        }
      }else{
        if (_isMicroMessager()) {
          // 此处记得修改为通过读取配置文件获取回调地址
          const WECHAT_CALL_BACK_DOMAIN = 'https://www.wph3.cn/'
          const APPID = 'wx3583e957567a826a'
          //const WECHAT_CALL_BACK_DOMAIN = 'http://www.wph1.cn/'
          //const APPID = 'wx7d6958ebf1065f67'
          const local = encodeURIComponent(WECHAT_CALL_BACK_DOMAIN + '#/home') //获取当前页面地址作为回调地址
          const myUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + local +'&response_type=code&scope=snsapi_userinfo&state=2324#wechat_redirect'
          console.log("wechat login url " + myUrl)
          window.location.href = myUrl

        } else if (model.value == true) {
          mobilePopupShow.value = true;
        } else {
          getQrcode()
              .then(res => {
                state2.qrCode = res.data.url
                pcPopupShow.value = true;
                if(state2.scanStatus == 0){
                  checkQrResult()
                }
              })
              .catch((msg) => {})
        }
      }
    }

    const isShow = ref(false)
    const state = reactive({
      form: {
        username: '',
        password: ''
      },
      validatorTel: false,
      phoneGui: /^[0-9]{11}$/
    })
    state.validatorTel = (val) => state.phoneGui.test(val)
    const onSubmit = (values) => {
      store.dispatch('Login', state.form).then(() => {
        store.dispatch('GetInfo').then(() => {
          isLogin = store.getters.isLogin == 0 ? false : true;
          if(state2.to == 'mission'){
            router.push('/mission')
            mobilePopupShow.value = false;
            active.value = 2
          } else if (store.getters?.level == 0) {
            router.push('/')
            mobilePopupShow.value = false;
            active.value = 3
          } else {
            router.push('/chart')
            mobilePopupShow.value = false;
            active.value = 0
          }
        })
      }).catch(() => {
      })
    }
    const methods = reactive({
      changePasswordIcon () {
        isShow.value = !isShow.value
      }
    })

    onMounted(() => {
      if (route.name === "chart") {
        active.value = 0;
      } else if (route.name === 'examples') {
        active.value = 1
      } else if (route.name === "mission") {
        active.value = 2;
      } else if (route.name === "home") {
        active.value = 3;
      } else if (route.name === "name") {
        active.value = 4;
      }

  })

    watch(
        () => router.currentRoute.value,
        (newValue, oldValue) => {
          if (newValue.name === "chart") {
            active.value = 0;
          } else if (route.name === 'examples') {
            active.value = 1
          } else if (newValue.name === "mission") {
            active.value = 2;
          } else if (newValue.name === "home") {
            active.value = 3;
          } else if (newValue.name === "name") {
            active.value = 4;
          }

    },
        { immediate: true }
    )

    return {
      active,
      model,

      mobilePopupShow,
      pcPopupShow,
      showPopup,
      isLogin,
      ...toRefs(state2),
      checkQrResult,

      isShow,
      onSubmit,
      ...toRefs(methods),
      ...toRefs(state),

      toMission,
      toHome
    }
  },
}
</script>

<style>
.container {
  height: calc(100% - 0px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

.sider {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 0 0 20%;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.btn {
  height: 50px;
  font-size: 15px;
  border: none;
  background-color: #f5f5f5;
}

.btn:hover,
.btn:active {
  background-color: white;
  color: black;
}

.btn:focus {
  background-color: white;
  color: black;
}

.right-content {
  flex: 1;
  box-sizing: border-box;
  overflow: auto;
  background-color: #fff;
  padding-bottom: 80px;
}

.right-content-sub {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-top: 0px;
}

.box {
  padding-top:10px;
  flex-basis: calc(50% - 22px);
  aspect-ratio: 1/1;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.box-content {
  flex-basis: 100%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: -5px;
  margin-top: 8px;
}

.description {
  font-size: 14px;
  color: #999;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box img {
  display: block;
  max-width: 20%;
  max-height: 20%;
}

@media only screen and (min-width: 700px) {
  .container{
    width: 640px;
    margin: 0 auto;
  }
  .van-tabbar{
    width: 640px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .pcPopup{
    font-size: 14px;
    width: 560px;
  }
}

</style>
